/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import { useLocation } from 'react-router-dom';
import { useCustomerStore } from '../customer';
import { capitalizeFirstLetter } from './capitalizeFirstLetter';

const doesMatch = (href: string, regex: string | undefined) => regex && href.match(RegExp(regex));

export function useBreadcrumbs() {
  const location = useLocation();
  const { currentCustomer } = useCustomerStore();
  const breadcrumbLabels = [
    ['^/customers/[^/]+$', currentCustomer?.orgName],
    ['/serviceaccount$', 'Service Accounts'],
    ['/users$', 'Users and Roles'],
    ['/reports$', 'Saved Reports'],
    ['/dataservice$', 'Data Service'],
    ['/savedbackups$', 'Saved Backups'],
    ['/serviceaccount/create$', 'Create Service Account'],
    ['/auditlog$', 'Audit Log'],
    ['/devicedetails$', 'Device Details']
  ];

  function title(href: string) {
    const breadcrumb = breadcrumbLabels.find(([regex, _]) => doesMatch(href, regex));
    return breadcrumb ? breadcrumb[1] : undefined;
  }

  const breadcrumbs = () => {
    // Remove any query parameters, as those aren't included in breadcrumbs
    const pathWithoutQuery = location.pathname?.split('?')[0];

    const nestedRoutes = pathWithoutQuery?.split('/').filter(v => v.length > 0);

    const crumblist = nestedRoutes?.map((subpath, idx) => {
      const href = '/' + nestedRoutes?.slice(0, idx + 1).join('/');
      const label = title(href) || subpath;

      return idx === nestedRoutes?.length - 1
        ? { label: capitalizeFirstLetter(label) }
        : { label: capitalizeFirstLetter(label), link: href };
    });
    //customers have already been replaced with customer's name hence remove it from list
    if (crumblist?.length && crumblist[0] && crumblist[0].link === '/customers') {
      crumblist.shift();
    }
    return crumblist?.length ? [{ label: 'Home', link: '/' }, ...crumblist] : [];
  };

  return {
    breadcrumbs: breadcrumbs()
  };
}
