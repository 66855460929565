/*
 * Copyright 2023 Tridium Inc. All rights reserved.
 */
import {
  HISTORY_TAG_ID,
  TYPE_TAG_ID,
  RANGE_TAG_ID,
  DATATYPE_TAG_ID,
  TELEMETRY_TAG_ID,
  DISPLAYNAME_TAG_ID,
  FALSE_TEXT_TAG_ID,
  NAME_TAG_ID,
  TRUE_TEXT_TAG_ID,
  UNIT_TAG_ID,
  SLOTH_PATH,
} from '../utils/Constants/chartConstants';
import { first } from '../utils/first';
import { IPointModel } from '../api/entityModel';
import { IPointInfo } from './PointHandlerInterfacesAndTypes';
import { getPointType } from './ChartHelper';

export function isPointForDisplay({ tags }: Pick<IPointModel, 'tags'>) {
  if (!tags.some(({ tagId }) => tagId === TELEMETRY_TAG_ID)) {
    return false;
  }
  switch (
    getPointType(
      first(
        tags.find(({ tagId }) => tagId === DATATYPE_TAG_ID)?.tagValue,
        tags.find(({ tagId }) => tagId === TYPE_TAG_ID)?.tagValue
      )
    )
  ) {
    case 'enum':
      return tags.some(({ tagId }) => tagId === RANGE_TAG_ID);
    case 'string':
      return false;
    case 'others':
      return false;
    default:
      return true;
  }
}

export function pointModelToPointInfo({
  displayName,
  cloudId,
  tags,
  outValue,
  facets,
  links
}: Pick<
  IPointModel,
  'displayName' | 'cloudId' | 'tags' | 'outValue' | 'facets' | 'links'
>): IPointInfo {
  const telemetryId = tags.find(
    ({ tagId }) => tagId === TELEMETRY_TAG_ID
  )?.tagValue;
  return {
    id: telemetryId ?? cloudId,
    telemetryId,
    pointId: cloudId,
    key: `{telemetryId:${telemetryId},cloudId:${cloudId}}`,
    historyName:
      first(
        tags.find(({ tagId }) => tagId === HISTORY_TAG_ID)?.tagValue,
        tags.find(({ tagId }) => tagId === DISPLAYNAME_TAG_ID)?.tagValue,
        tags.find(({ tagId }) => tagId === NAME_TAG_ID)?.tagValue,
        displayName,
        cloudId
      ) ?? '',
    displayName:
      first(
        tags.find(({ tagId }) => tagId === DISPLAYNAME_TAG_ID)?.tagValue,
        tags.find(({ tagId }) => tagId === NAME_TAG_ID)?.tagValue,
        tags.find(({ tagId }) => tagId === HISTORY_TAG_ID)?.tagValue,
        displayName,
        cloudId
      ) ?? '',
    kind: getPointType(
      first(
        tags.find(({ tagId }) => tagId === DATATYPE_TAG_ID)?.tagValue,
        tags.find(({ tagId }) => tagId === TYPE_TAG_ID)?.tagValue
      )
    ),
    type: tags.find(({ tagId }) => tagId === TYPE_TAG_ID)?.tagValue ?? '',
    units: tags.find(({ tagId }) => tagId === UNIT_TAG_ID)?.tagValue ?? '',
    range: tags.find(({ tagId }) => tagId === RANGE_TAG_ID)?.tagValue ?? '',
    trueText:
      tags.find(({ tagId }) => tagId === TRUE_TEXT_TAG_ID)?.tagValue ?? null,
    falseText:
      tags.find(({ tagId }) => tagId === FALSE_TEXT_TAG_ID)?.tagValue ?? null,
    slotPath:
      tags
        .find(({ tagId }) => tagId === SLOTH_PATH)
        ?.tagValue.split('slot:')[1] ?? null,
    outValue: outValue,
    facets: facets,
    links
  };
}
