/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */
import React, { useState } from 'react';
import {
  NiagaraButton2,
  NiagaraModal,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { Loader, Modal } from '@scuf/common';
import { NiagaraMyUser } from '../../../api/management';
import './UserInviteActionModal.scss';
import { IUserInvitationRequest, rejectUserInvitationAsync, acceptUserInvitationAsync } from '../../../api/userInvitation';
import { ApiError } from '../../../utils/niagaraHttp';
import { showErrorToast } from '../../../toast/Toast';
interface IUserInviteActionModal {
  onCancel: () => void;
  userInvitationDetails: IUserInvitationRequest;
  currentUser: NiagaraMyUser;
}
const UserInviteActionModal = ({
  onCancel,
  userInvitationDetails,
  currentUser,
}: IUserInviteActionModal) => {
  const DEFAULT_ORG = 'NIAGARA COMMUNITY';
  const [isLoading, setIsLoading] = useState(false);
  const acceptInvite = () => {
    const id = userInvitationDetails?.id;
    setIsLoading(true);
    id &&
      acceptUserInvitationAsync(id)
        .then(() => {
          window.location.reload();
        })
        .catch((_: ApiError) => {
          setIsLoading(false);
          showErrorToast("We couldn't process your request, try again later");
        });
  };

  const rejectInvitation = async (userInvitationId: number) => {
    setIsLoading(true);
    userInvitationId && 
      rejectUserInvitationAsync(userInvitationId)
        .then(() => {
          window.location.reload();
        })
        .catch((_: ApiError) => {
          setIsLoading(false);
          showErrorToast("We couldn't process your request, try again later");
        });
  };
  return (
    <NiagaraModal className='user-invite-action-modal' open={true} size='small'>
      <Loader loading={isLoading} overlayOpacity={0.5} >
      <Modal.Header>
        <div className='modal-heading'>User Invitation</div>
      </Modal.Header>
      <Modal.Content>
        <div className='modal-content'>
          <p>
            Your partner, {userInvitationDetails.partnerName}, has invited you
            to join the {userInvitationDetails.customerName} organization on
            Niagara Cloud.
          </p>
          {currentUser && currentUser.organizationName !== DEFAULT_ORG && (
            <p>
              Note that accepting this invitation will remove you from your
              current organization {currentUser.organizationName}.
            </p>
          )}
          <p>
            Click 'Accept' below to join this organization
            {currentUser &&
              currentUser.organizationName !== DEFAULT_ORG &&
              `, declining will direct you to current organisation.`}
          </p>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className='button-container'>
          <NiagaraButton2
            type='secondary'
            content='Decline'
            disabled={isLoading}
            onClick={() => rejectInvitation(userInvitationDetails.id)}
          />
          <NiagaraButton2
            type='primary'
            content='Accept'
            disabled={isLoading}
            onClick={() => acceptInvite()}
          />
        </div>
      </Modal.Footer>
      </Loader>
    </NiagaraModal>
  );
};
export default UserInviteActionModal;
