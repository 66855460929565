/*
 * Copyright 2024 Tridium Inc. All rights reserved.
 */

import React, { useState } from 'react';
import {
  NiagaraModal,
  NiagaraButton2,
  NiagaraInput,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { Grid, Modal } from '@scuf/common';
import './ReRegisterDeviceModal.scss';
import { DeviceWithProjectIdAndSubscriptions } from '../ProjectStore';
import FederatedIdentityAuthenticator from '../../assets/image/federated-identity-authenticator.svg';
import PropertySheetPanel from '../../assets/image/property-sheet-panel.svg';
import { useDeviceRegistration } from './useDeviceRegistration';
import { LicenseTable } from './LicenseTable';
import { NoLicense } from './NoLicense';
import Status from '../../utils/Status';
import './RegisterNewDevicePage.scss';
interface IReRegisterDeviceModal {
  device: DeviceWithProjectIdAndSubscriptions;
  onClose: () => void;
  onReRegisterDevice: (r: string, l: string) => void;
}

const MAX_USER_CODE_LIMIT = 8;
const LICENSE_ERROR = 'Sorry, not able to fetch license information right now, Please try again.';
export function ReRegisterDeviceModal({ device, onClose, onReRegisterDevice }: IReRegisterDeviceModal) {
  const [registrationCode, setRegistrationCode] = useState<string | undefined>();
  const { status, licenses, error, update, selectedLicense } = useDeviceRegistration(
    registrationCode,
    device.deviceUuid
  );

  return (
    <NiagaraModal open={true} className='re-register-device'>
      <div className='scrollable-modal'>
        <Modal.Header>
          <div className='modal-heading'>{device.deviceName} : RE-REGISTRATION</div>
          <NiagaraButton2 type='secondary-link' icon='Close' iconColor='#606060' onClick={onClose} />
        </Modal.Header>
        <Modal.Content>
          <div className='re-register-device-form'>
            <div className='form-container'>
              <NiagaraInput
                label='Registration Code'
                className='full-width'
                placeholder='Enter registration code'
                onChange={(e) =>
                  e && e.length === MAX_USER_CODE_LIMIT ? setRegistrationCode(e) : setRegistrationCode(undefined)
                }
                maxLength={MAX_USER_CODE_LIMIT}
              />
            </div>
            {(!registrationCode || registrationCode?.length < MAX_USER_CODE_LIMIT) && (
              <div className='hint-image'>
                <h3 className='heading-get-user-code'>HOW TO GET REGISTRATION CODE</h3>
                <Grid className='user-code-margin'>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <div className='steps-container'>
                        <div className='step-title'>
                          <b>STEP 1:</b>
                        </div>
                        <div className='step-text'>Login to Niagara Workbench</div>
                      </div>
                      <div className='steps-container'>
                        <div className='step-title'>
                          <b>STEP 2:</b>
                        </div>
                        <div className='step-text'>
                          Under Niagara Cloud Service right click on <b>FederatedIdentityAuthenticator</b>&nbsp; to
                          start registration
                          <img
                            width='100%'
                            src={FederatedIdentityAuthenticator}
                            alt='federated identity authenticator'
                          />
                        </div>
                      </div>
                      <div className='user-code-note'>
                        <b>Note :</b> Registration Code is valid for only 15 mins once the registration begins
                      </div>
                    </Grid.Column>

                    <Grid.Column width={6} className='user-code-padding'>
                      <div className='steps-container'>
                        <div className='step-title'>
                          <b>STEP 3:</b>
                        </div>
                        <div className='step-text'>
                          Copy the registration code from the property sheet panel
                          <img width='100%' src={PropertySheetPanel} alt='property sheet panel' />
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            )}
            <Status
              status={status}
              error={
                error && {
                  ...{
                    ...error,
                    errorText: error?.errorType === 'Default' ? LICENSE_ERROR : error?.errorText,
                  },
                }
              }
            >
              <div className='license-title'>LICENSES</div>
              {!!licenses && licenses.length > 0 ? (
                <LicenseTable
                  licenses={licenses}
                  onSelect={(license) => update({ status, licenses, error, selectedLicense: license })}
                  selected={selectedLicense}
                />
              ) : (
                <NoLicense />
              )}
            </Status>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className='button-container'>
            <NiagaraButton2 type='secondary' content='Cancel' onClick={onClose} />
            <NiagaraButton2
              type='primary'
              content='Done'
              disabled={!selectedLicense}
              onClick={() =>
                registrationCode &&
                selectedLicense?.licenseId &&
                onReRegisterDevice(registrationCode, selectedLicense.licenseId)
              }
            />
          </div>
        </Modal.Footer>
      </div>
    </NiagaraModal>
  );
}
